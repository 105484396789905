import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function FAQ() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Container
      id="faq"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Typography
        component="h2"
        variant="h4"
        color="text.primary"
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        Frequently Asked Questions
      </Typography>
      <Box sx={{ width: '100%' }}>
        {/* FAQ Entry 1 */}
        <Accordion
          expanded={expanded === 'panel1'}
          onChange={handleChange('panel1')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography>How does Biz2Biz define the scope of services?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              It all starts with a conversation, understanding the client's needs through discovery, followed by outlining the scope of services. Once we agree on the defined scope, a letter of intent is executed, outlining the thresholds for the defined scope along with the stipulated timelines.
            </Typography>
          </AccordionDetails>
        </Accordion>

        {/* FAQ Entry 2 */}
        <Accordion
          expanded={expanded === 'panel2'}
          onChange={handleChange('panel2')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <Typography>How does Biz2Biz facilitate credit structuring and soliciting financing from one or multiple lenders?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              It's a simple process driven by identifying the client's business financing requirements. We then develop a business plan supported by financial projections after consulting with the client, and set them up to obtain financing from a suitable lender on attractive available lending terms.
            </Typography>
          </AccordionDetails>
        </Accordion>

        {/* FAQ Entry 3 */}
        <Accordion
          expanded={expanded === 'panel3'}
          onChange={handleChange('panel3')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3-content"
            id="panel3-header"
          >
            <Typography>How does Biz2Biz enhance business performance through technology?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              At Biz2Biz, we streamline technology adoption by managing the transition process effectively. Our experts ensure that your team embraces new technologies with minimal disruption, maximizing the potential and driving innovation within your organization.
            </Typography>
          </AccordionDetails>
        </Accordion>

        {/* FAQ Entry 4 */}
        <Accordion
          expanded={expanded === 'panel4'}
          onChange={handleChange('panel4')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4-content"
            id="panel4-header"
          >
            <Typography>What strategies does Biz2Biz use to facilitate business transformation?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Biz2Biz focuses on aligning your organization's operations with customer needs, enhancing performance and sustainability through targeted operational designs and agile transformations. Our approach includes deep benchmarking, leadership development, and strategic organizational redesign.
            </Typography>
          </AccordionDetails>
        </Accordion>

        {/* FAQ Entry 5 */}
        <Accordion
          expanded={expanded === 'panel5'}
          onChange={handleChange('panel5')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel5-content"
            id="panel5-header"
          >
            <Typography>How does Biz2Biz ensure the effectiveness of governance and compliance?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              We implement strong governance frameworks that promote accountability and transparency. Our governance practices are designed to support sustainable growth and enable swift, purposeful decision-making within your organization.
            </Typography>
          </AccordionDetails>
        </Accordion>

        {/* FAQ Entry 6 */}
        <Accordion
          expanded={expanded === 'panel6'}
          onChange={handleChange('panel6')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel6-content"
            id="panel6-header"
          >
            <Typography>Can Biz2Biz help my organization with leadership and cultural transformations?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Yes, Biz2Biz specializes in fostering strong leadership cultures and continuous improvement environments. We assist in transforming your organizational culture through comprehensive assessments, leadership coaching, and effective change management strategies.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Container>
  );
}
