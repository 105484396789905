import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import GhalibImage from "../assets/ghalib.jpeg";
import AhmerImage from "../assets/ahmer.jpeg";

const tiers = [
  {
    title: "Ghalib Salam",
    subheader: "Principal and Consultant",
    profileImage: GhalibImage,
    description: [
      "Ghalib is a seasoned corporate banker with extensive experience of more than 27 years in business advisory services. As the Founder & Principal at Biz2Biz Networks Inc., he brings a wealth of knowledge and expertise in areas such as corporate finance, business strategy, mergers and acquisitions, financial analysis, and more.",
      "His expertise is dealing with businesses, which coupled with his skill in providing right business advice, have led him to be a dedicated and invaluable resource to his clients. His significant career has honed Ghalib’s ability to serve his clients through different stages in a business cycle. In order to achieve his client’s goals, he works closely with other partners to get the right valued proposition. This team building approach gives his clients a complete range of services – including M&A, growth advisory, succession and estate planning. He holds Bachelor in Commerce and Masters in Business Administration degrees.",
      "Ghalib actively participates in volunteering activities for business education and an active fundraiser for various charity events. He is a passionate triathlete having Ironman, Swissman, Norseman (world’s toughest triathlon) finisher titles events including 100 miler trail run finisher – having 20,000 feet net ascent. Married, with three children.",
    ],
    buttonText: "View LinkedIn",
    buttonVariant: "contained",
    linkedinUrl: "https://ca.linkedin.com/in/ghalib-salam",
  },
  {
    title: "Ahmer Khan",
    subheader: "Tech Lead and Consultant",
    profileImage: AhmerImage,
    description: [
      "Ahmer is a seasoned and passionate IT professional who brings over 25 years of panoramic Consulting, IT/Advisory expertise working with over 65+ Clients across North America, Europe and Asia.",
      "Ahmer has worked extensively as a Technical Oracle Solutions Architect and Business leader to provide guidance, support, vision to optimize, enhance, design, re-architecture, create roadmaps, identify intricacies and customized strategies leveraging industry best practices to create, build and deliver robust, scalable, elastic, fault-tolerant and highly available advanced and complex Oracle Database Enterprise wide systems on-premise, internal/external Cloud, AWS and OCI Cloud environments.",
      "His Client centric solutions oriented and analytical approach has demonstrated and proven abilities to deliver optimal solutions.",
    ],
    buttonText: "View LinkedIn",
    buttonVariant: "contained",
    linkedinUrl:
      "https://ca.linkedin.com/in/ahmer-khan-architect-innovator-leader-visionary-disruptor-advisor-b776a210",
  },
];

export default function OurTeam() {
  return (
    <Container
      id="ourteam"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: "100%", md: "60%" },
          textAlign: { sm: "left", md: "center" },
        }}
      >
        <Typography component="h2" variant="h4" color="text.primary">
          Our Team
        </Typography>
        <Typography variant="body1" color="text.secondary">
          Meet our dedicated team members who bring a wealth of experience and
          expertise.
        </Typography>
      </Box>
      <Grid container spacing={3} alignItems="center" justifyContent="center">
        {tiers.map((tier) => (
          <Grid item key={tier.title} xs={12} sm={6} md={4}>
            <Card
              sx={{
                display: "flex",
                flexDirection: "column",
                height: 500,
                justifyContent: "space-between",
              }}
            >
              <CardContent sx={{ flexGrow: 1, overflow: "auto" }}>
                <Box
                  sx={{
                    mb: 1,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography component="h3" variant="h5">
                    {tier.title}
                  </Typography>
                  <Box
                    component="img"
                    src={tier.profileImage}
                    alt={tier.title}
                    sx={{
                      width: 80,
                      height: 80,
                      borderRadius: "50%",
                      ml: 2,
                    }}
                  />
                </Box>
                <Typography
                  component="h4"
                  variant="subtitle1"
                  color="text.secondary"
                  sx={{ mb: 2 }}
                >
                  {tier.subheader}
                </Typography>
                <Divider
                  sx={{ my: 2, opacity: 0.2, borderColor: "grey.500" }}
                />
                {tier.description.map((line, index) => (
                  <Box
                    key={index}
                    sx={{
                      py: 1,
                      display: "flex",
                      gap: 1.5,
                      alignItems: "center",
                    }}
                  >
                    <CheckCircleRoundedIcon
                      sx={{
                        width: 20,
                        color: "primary.main",
                      }}
                    />
                    <Typography component="span" variant="subtitle2">
                      {line}
                    </Typography>
                  </Box>
                ))}
              </CardContent>
              <CardActions>
                <Button
                  fullWidth
                  variant={tier.buttonVariant}
                  component="a"
                  href={tier.linkedinUrl}
                  target="_blank"
                >
                  {tier.buttonText}
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
