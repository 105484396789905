import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import GavelIcon from '@mui/icons-material/Gavel';
import AssessmentIcon from '@mui/icons-material/Assessment';

const solutions = [
  {
    icon: <BusinessCenterIcon />,
    title: 'Mergers, Acquisitions, and Divestitures',
    description: 'Sourcing and structuring transactions to grow your business or realize value.',
  },
  {
    icon: <AccountBalanceIcon />,
    title: 'Capital Advisory',
    description: 'Independent advice across a range of private financing alternatives.',
  },
  {
    icon: <TrendingUpIcon />,
    title: 'Transaction Advisory Services',
    description: 'Scalable, risk-focused due diligence to help you make the right decisions.',
  },
  {
    icon: <GavelIcon />,
    title: 'Valuations',
    description: 'Understanding the value of your business.',
  },
  {
    icon: <AssessmentIcon />,
    title: 'Integrated Service Offerings',
    description: 'Corporate finance, productivity improvement, restructuring, and other strategic advisory services.',
  },
];

export default function Solutions() {
  return (
    <Box
      id="solutions"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        color: 'white',
        bgcolor: '#06090a',
      }}
    >
      <Container
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: '100%', md: '60%' },
            textAlign: { sm: 'left', md: 'center' },
          }}
        >
          <Typography component="h2" variant="h4">
            Solutions
          </Typography>
          <Typography variant="body1" sx={{ color: 'grey.400' }}>
            We bring a range of services to individuals and businesses experiencing financial challenges, providing workable solutions that maximize value.
          </Typography>
        </Box>
        <Grid container spacing={2.5}>
          {solutions.map((solution, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Stack
                direction="column"
                color="inherit"
                component={Card}
                spacing={1}
                sx={{
                  p: 3,
                  height: '100%',
                  border: '1px solid',
                  borderColor: 'grey.800',
                  background: 'transparent',
                  backgroundColor: 'grey.800',
                }}
              >
                <Box sx={{ opacity: '50%' }}>{solution.icon}</Box>
                <div>
                  <Typography fontWeight="medium" gutterBottom>
                    {solution.title}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'grey.400' }}>
                    {solution.description}
                  </Typography>
                </div>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
