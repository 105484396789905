import React from "react";
import ReactDOM from "react-dom/client";
import LandingPage from "./LandingPage";
import PrivacyPolicy from "./PrivacyPolicy";

import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';


const root = ReactDOM.createRoot(document.getElementById("root"));


root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
      </Routes>
    </Router>
  </React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
