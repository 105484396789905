import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import SpeedIcon from "@mui/icons-material/Speed";
import BusinessIcon from "@mui/icons-material/Business";
import SummarizeIcon from "@mui/icons-material/Summarize";
import BuildIcon from "@mui/icons-material/Build";
import PsychologyIcon from "@mui/icons-material/Psychology";

import financialReview from "../assets/financialReview.png";
import businessPlans from "../assets/businessPlans.png";
import acceleratedma from "../assets/acceleratedma.png";
import turnaround from "../assets/turnaround.png";
import performance from "../assets/performance.png";

const items = [
  {
    icon: <BusinessIcon />,
    title: "Business plans",
    description:
      "Looking for refinancing or to attract new investors? We can help prepare a business plan that can assist with your game plan.",
    imageLight: `url(${businessPlans})`,
    imageDark: `url(${businessPlans})`,
  },
  {
    icon: <SummarizeIcon />,
    title: "Viability and financial reviews",
    description:
      "Gain insights with a report that provides a detailed analysis and recommendations.",
    imageLight: `url(${financialReview})`,
    imageDark: `url(${financialReview})`,
  },
  {
    icon: <SpeedIcon />,
    title: "Accelerated M&A",
    description:
      "Our professionals have the experience to project manage purchase or sale transactions within weeks and, when necessary, bring together recovery, corporate finance and taxation specialists to assist.",
    imageLight: `url(${acceleratedma})`,
    imageDark: `url(${acceleratedma})`,
  },
  {
    icon: <BuildIcon />,
    title: "Turnaround and restructuring",
    description:
      "Transform your failing business with our multi-disciplinary team, which assesses cash flow, develops new strategies, assists in divestitures, and designs new capital structures.",
    imageLight: `url(${turnaround})`,
    imageDark: `url(${turnaround})`,
  },
  {
    icon: <PsychologyIcon />,
    title: "Strategic performance review",
    description:
      "Our professionals have the experience to project manage purchase or sale transactions within weeks and, when necessary, bring together recovery, corporate finance and taxation specialists to assist.",
    imageLight: `url(${performance})`,
    imageDark: `url(${performance})`,
  },
];

export default function Features() {
  const [selectedItemIndex, setSelectedItemIndex] = React.useState(0);

  const handleItemClick = (index) => {
    setSelectedItemIndex(index);
  };

  const selectedFeature = items[selectedItemIndex];

  return (
    <Container id="services" sx={{ pt: 6, pb: 10 }}>
      <Box textAlign="center" mb={4}>
        <Typography
          component="h2"
          variant="h4"
          color="text.primary"
          gutterBottom
        >
          Services
        </Typography>
        <Typography variant="body1" color="text.secondary">
          What do we offer?
        </Typography>
      </Box>
      <Grid container spacing={4}>
        <Grid item xs={12} md={5}>
          <Stack spacing={2}>
            {items.map(({ icon, title }, index) => (
              <Button
                key={index}
                onClick={() => handleItemClick(index)}
                variant="outlined"
                color={selectedItemIndex === index ? "primary" : "inherit"}
                startIcon={icon}
                sx={{
                  justifyContent: "flex-start",
                  textAlign: "left",
                  color:
                    selectedItemIndex === index
                      ? "primary.main"
                      : "text.primary",
                }}
              >
                {title}
              </Button>
            ))}
          </Stack>
        </Grid>
        <Grid item xs={12} md={7}>
          <Card
            variant="outlined"
            sx={{ height: "100%", display: "flex", flexDirection: "column" }}
          >
            <Box
              sx={{
                backgroundImage: (theme) =>
                  theme.palette.mode === "light"
                    ? items[selectedItemIndex].imageLight
                    : items[selectedItemIndex].imageDark,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                height: 0,
                paddingBottom: "56.25%",
              }}
            />
            <Box sx={{ p: 3 }}>
              <Typography color="text.primary" variant="h6" gutterBottom>
                {selectedFeature.title}
              </Typography>
              <Typography color="text.secondary" variant="body1" gutterBottom>
                {selectedFeature.description}
              </Typography>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}
