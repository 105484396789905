import * as React from "react";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";

const caseStudies = [
  {
    name: "Technology Adoption",
    occupation: "Multiple Suppliers Acquisitions",
    keyScope: [
      "Conduct due diligence on various suppliers A/R",
      "Assess A/R acquisition value",
      "Arrange acquisition financing through a lender",
      "Acquisition tasks fulfilled and navigated with suppliers, legal, accountants, and lenders",
    ],
    status: "Completed - Successfully arranged acquisition financing",
  },
  {
    name: "QSR Banner Complete Business Transformation Mandate",
    occupation: "",
    keyScope: [
      "Assess existing operating arrangements for payments, POS, CRM, ERP, customer loyalty",
      "Completed market due diligence with multiple vendors by circulating a RFP",
      "Conduct comparable analysis based on competitive tech-based AI infused offerings",
      "Provide recommendation to fulfill mandate requirements",
    ],
    status: "Completed - Successfully recommended findings to the client",
  },
  {
    name: "Arrange Build-Out Financing for Multiple Franchisee Operations",
    occupation: "",
    keyScope: [
      "Conduct comprehensive analysis of existing operations and newly proposed locations",
      "Create business plans with projections and align the financing needs with the right lending partner",
      "Solicit required financing and facilitate in execution",
      "Prepare the banner expansion to meet financing needs for future expansions",
    ],
    status:
      "Completed – Successfully funded existing operation and prepared the banner for future business expansions",
  },
  {
    name: "Pharmacy Acquisition",
    occupation: "Independent & Start-Up Pharmacies",
    keyScope: [
      "Conduct acquisition due diligence on existing operating pharmacies by new partners",
      "Identify suitable acquisition financing options from lenders (including start-up financing)",
      "Arrange and qualify acquisition financing from the lender for ongoing multiple acquisitions",
    ],
    status:
      "Mandate Completed - Successfully arranged acquisition financing and fulfilled multiple acquisitions",
  },
  {
    name: "Fixed Assets Expansion",
    occupation: "Supported by Third Party Leases",
    keyScope: [
      "Conduct due diligence on growth of cash flows resulting in confirmed increase in lease rental income",
      "Arrange financing for fixed assets buildout (through multiple lenders) based on anticipated increased cashflows cycle",
    ],
    status:
      "Mandate Completed - Cash flows financing arranged needed for expansion",
  },
  {
    name: "Franchised QSR Banner Sale",
    occupation: "",
    keyScope: [
      "Conduct due diligence and conduct road shows (nationally and internationally)",
      "Represent exclusively sell-side as a trusted advisor",
    ],
    status: "In progress – Potential acquisition discussions underway",
  },
  {
    name: "Open Banking Fintech",
    occupation: "Localization/Adoption",
    keyScope: [
      "Partner of a working group responsible for business strategy formation",
      "Creating strategic alliance with established international partners",
      "Business development and commercialization for effective localization and adoption",
    ],
    status: "Mandate Completed",
  },
  {
    name: "QSR Banner Expansion",
    occupation: "Middle Eastern Markets",
    keyScope: [
      "Assess existing operating arrangements for successful adoption in ME markets",
      "Prepare banner integration through strategic alliances in ME different territories",
      "Arrange alliance partners qualification with the franchisor for successful territorial partnerships",
      "Facilitation in team’s formation for territorial operating arrangements in place",
    ],
    status: "Ongoing",
  },
  {
    name: "AI-Powered IP Development",
    occupation: "B2B Marketplace",
    keyScope: [
      "Concept and business strategy formation",
      "IP development and co-ordination within tech ecosystem (legal, registration, application process etc.)",
      "Data accumulation, snowflake presentation, platform development until fulfillment",
    ],
    status: "Ongoing - Platform ownership by Biz2Biz Networks",
  },
];

export default function CaseStudies() {
  const [open, setOpen] = React.useState(false);
  const [selectedCaseStudy, setSelectedCaseStudy] = React.useState(null);

  const handleOpen = (caseStudy) => {
    setSelectedCaseStudy(caseStudy);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Container
      id="case-studies"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography
        component="h2"
        variant="h4"
        color="text.primary"
        sx={{ mb: 2, textAlign: "center" }}
      >
        Case Studies
      </Typography>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        {caseStudies.map((caseStudy, index) => (
          <Grid item xs={12} sm={6} md={4} key={index} sx={{ display: "flex" }}>
            <Card
              onClick={() => handleOpen(caseStudy)}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexGrow: 1,
                p: 2,
                minHeight: "100px",
                borderRadius: "8px",
                cursor: "pointer",
                boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                transition: "box-shadow 0.3s ease",
                "&:hover": {
                  boxShadow: "0 8px 16px rgba(0,0,0,0.2)",
                },
              }}
            >
              <Typography variant="h6" color="text.primary" align="center">
                {caseStudy.name}
              </Typography>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "80%",
              maxWidth: "600px",
              bgcolor: "background.paper",
              borderRadius: "16px",
              boxShadow: 24,
              p: 4,
            }}
          >
            {selectedCaseStudy && (
              <>
                <Typography variant="h6" component="h2" align="center">
                  {selectedCaseStudy.name}
                </Typography>
                <Typography
                  variant="body1"
                  color="text.secondary"
                  align="center"
                >
                  {selectedCaseStudy.occupation}
                </Typography>
                <Typography
                  variant="body2"
                  color="text.primary"
                  sx={{ mt: 2, fontWeight: "bold" }}
                >
                  Key Scope:
                </Typography>
                <ul>
                  {selectedCaseStudy.keyScope.map((scope, index) => (
                    <Typography
                      key={index}
                      variant="body2"
                      color="text.secondary"
                      component="li"
                    >
                      {scope}
                    </Typography>
                  ))}
                </ul>
                <Typography variant="body2" color="text.primary" sx={{ mt: 2 }}>
                  {selectedCaseStudy.status}
                </Typography>
              </>
            )}
          </Box>
        </Fade>
      </Modal>
    </Container>
  );
}
